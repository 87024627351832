import { showErrorSnackbar, showSuccessSnackbar } from './layout';
import {
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_ERROR,
} from '../consts/snackbarMessages';

export const uploadImage = (file) => async (dispatch, _, { axios }) => {
  try {
    console.log('Image received for upload:', file);

    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post('/images/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch(showSuccessSnackbar(UPLOAD_IMAGE_SUCCESS));
    return data;
  } catch (error) {
    console.error('Image upload failed:', error);
    dispatch(showErrorSnackbar(UPLOAD_IMAGE_ERROR));
    throw error;
  }
};
