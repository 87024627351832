import React, { Fragment, useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { LogoLoader, SettingsHeader } from 'components/Base';
import { SubscriptionDetailsCard } from '../Shared/components';
import { useSubscription } from 'components/Contexts';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { StringParam, useQueryParam } from 'use-query-params';
import { useLazyLoading } from 'state/hooks';
import { stripeCheckoutSuccessful } from 'state/actions';

const Subscriptions = ({ ad, subscription }) => {
  const { isDefaultPlan, openUpgradeDialog, loading } = useSubscription();
  const [checkoutSessionId, setCheckoutSessionId] = useQueryParam(
    'checkoutSessionId',
    StringParam
  );
  const [subscribedPlan, setSubscribedPlan] = useQueryParam(
    'plan',
    StringParam
  );
  const [handledSessionId, setHandledSessionId] = useState(false);

  const [upgradeCustomerPlan, { upgrading, error }] = useLazyLoading({
    action: stripeCheckoutSuccessful,
    onSuccess: () => {
      setCheckoutSessionId(undefined);
      setSubscribedPlan(undefined);
      window.location.reload();
    },
  });

  useEffect(() => {
    console.log(checkoutSessionId, !upgrading && !handledSessionId);
    if (checkoutSessionId && !upgrading && !handledSessionId) {
      console.log(checkoutSessionId);
      upgradeCustomerPlan(checkoutSessionId, subscribedPlan);
      setHandledSessionId(true);
    }
    // If you want to reset the handled state when checkoutSessionId is undefined
    if (!checkoutSessionId) {
      setHandledSessionId(false);
    }
  }, [
    checkoutSessionId,
    upgradeCustomerPlan,
    upgrading,
    handledSessionId,
    subscribedPlan,
  ]);

  if (loading) return <LogoLoader />;

  const openCustomerPortal = () => {
    const endpoint = process.env.REACT_APP_API_ENDPOINT;

    const env =
      endpoint.includes('cpqnzwyrn7') /* dev apprunner */ ||
      endpoint.includes('localhost')
        ? 'dev'
        : 'prd';
    if (env === 'dev') {
      window.open(
        'https://payments.semplates.io/p/login/test_aEU7wz4bi1NZdBC8ww',
        '_blank'
      );
    } else {
      window.open(
        'https://payments.semplates.io/p/login/3cseW47HwbHo61a144',
        '_blank'
      );
    }
  };

  const cardsContent = [
    {
      metricLabel: 'Manage your subscription',
      metricValue: subscription.name,
      metricThreshold: null,
      metricCaption:
        "Only billing admins have the rights to edit, update, or cancel the plan. If you're not the billing admin, please contact the appropriate person.",
      onClick: () => openCustomerPortal(),
    },
    {
      metricLabel: 'Managed Templates',
      metricValue: subscription.templates.count,
      metricThreshold: subscription.templateLimit + '*',
      metricCaption:
        '*included in your plan. Additional templates are $' +
        subscription.additionalTemplateCosts +
        ' / month.',
    },
    {
      metricLabel: 'Team Members',
      metricValue: subscription.userCount,
      metricThreshold: subscription.userLimit + '*',
      metricCaption:
        '*included in your plan. Additional members are $' +
        subscription.additionalUserCosts +
        ' / month.',
    },
    {
      metricLabel: 'AWS Accounts',
      metricValue: subscription.awsAccountsCount,
      metricThreshold: subscription.awsAccountLimit + '*',
      metricCaption:
        '*included in your plan. Additional members are $' +
        subscription.additionalAccountCosts +
        ' / month.',
    },
  ];

  const cardsContentTrial = [
    {
      metricLabel: 'Current Plan',
      metricValue: subscription.name,
      metricCaption: 'You can upgrade your plan anytime.',
      onClick: () => openUpgradeDialog(),
    },
    {
      metricLabel: 'Managed Templates',
      metricValue: subscription.templates.count,
      metricThreshold: subscription.templateLimit,
      metricCaption: 'Upgrade your account to use more templates.',
      onClick: () => openUpgradeDialog(),
    },
    {
      metricLabel: 'Team Members',
      metricValue: subscription.userCount,
      metricThreshold: subscription.userLimit,
      metricCaption: 'Upgrade your account to collaborate with others.',
      onClick: () => openUpgradeDialog(),
    },
    {
      metricLabel: 'AWS Accounts',
      metricValue: subscription.awsAccountsCount,
      metricThreshold: subscription.awsAccountLimit,
      metricCaption: 'Upgrade your account to manage multiple aws accounts.',
      onClick: () => openUpgradeDialog(),
    },
  ];

  if (error) {
    return (
      <Alert severity="danger">
        Something went wrong when updating your company plan. Please contact
        support@semplates.io
      </Alert>
    );
  }

  if (checkoutSessionId) {
    return (
      <>
        <LogoLoader />
        <Alert severity="info">
          Your subscription is being updated. Please wait.
        </Alert>
      </>
    );
  }

  return (
    <div style={{ height: '100%' }}>
      <SettingsHeader title={`Subscription Details`} />

      <Grid container spacing={3}>
        {isDefaultPlan ? (
          <Fragment>
            {cardsContentTrial.map((card, index) => {
              return (
                <Grid item xs={6} sm={5} md={4} lg={3} key={index}>
                  <SubscriptionDetailsCard
                    metricLabel={card.metricLabel}
                    metricValue={card.metricValue}
                    metricThreshold={card.metricThreshold}
                    metricCaption={card.metricCaption}
                    onClickAction={card.onClick}
                  />
                </Grid>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <Box m={1} style={{ width: '100%' }}>
              <Alert severity="info">
                Please note, that we currently only offer the management of
                plans, but not of aws accounts, templates and users. This will
                be possible in a future release.
              </Alert>
            </Box>
            {cardsContent.map((card, index) => {
              return (
                <Grid item xs={6} sm={5} md={4} lg={3} key={index}>
                  <SubscriptionDetailsCard
                    metricLabel={card.metricLabel}
                    metricValue={card.metricValue}
                    metricThreshold={card.metricThreshold}
                    metricCaption={card.metricCaption}
                    onClickAction={card.onClick}
                  />
                </Grid>
              );
            })}
          </Fragment>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subscription: state.company.subscription,
  };
};

export default connect(mapStateToProps)(Subscriptions);
