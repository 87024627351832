export const PASSWORD_UPDATE_SUCCESS = 'Password successfully updated';
export const PASSWORD_RESET_SUCCESS = 'Password reset mail send';

export const PASSWORD_RESET_ERROR =
  'Something went wrong when resetting your password';
export const SEND_VERIFICATION_ERROR =
  'Something went wrong when resending your verification email';

export const GET_USER_DETAILS_ERROR =
  'We ran into a problem when trying to log you in. Please contact the support team.';

export const UPDATE_USER_DETAILS_ERROR =
  'We ran into a problem saving your details';
export const UPDATE_USER_DETAILS_SUCCESS =
  'Successfully updated your information!';

export const UPLOAD_IMAGE_ERROR = 'We ran into a problem uploading your image';
export const UPLOAD_IMAGE_SUCCESS = 'Successfully uploaded your image';

export const ACCEPT_TERMS_SUCCESS = 'Thanks for using our service!';

export const ACCEPT_TERMS_ERROR = 'An error occured, please try again.';

export const CREATE_USER_ACCOUNT_ERROR =
  'Something went wrong while setting up your account. Please try again!';
export const CREATE_USER_ACCOUNT_SUCCESS = 'Your account has been created';

export const CREATE_EXISTING_TEMPLATE_ERROR =
  'A template with that name already exists in your SES. Use SES Import in order to edit existing templates or choose a different name for the one you are trying to associate with this account.';
export const CREATE_TEMPLATE_ERROR =
  'Something went wrong creating your template. Full error message: ';
export const CREATE_TEMPLATE_SUCCESS = 'Successfully created new template.';

export const DUPLICATE_TEMPLATE_ERROR =
  'Something went wrong while duplicating template. Please try again!';
export const DUPLICATE_TEMPLATE_SUCCESS =
  'Successfully duplicated your template.';
export const DUPLICATE_TEMPLATE_IN_OTHER_REGION_SUCCESS =
  'Successfully replicated your template in account: ';

export const DELETE_TEMPLATE_ERROR =
  'Something went wrong deleting your template';
export const DELETE_TEMPLATE_SUCCESS = 'Success! Your template was deleted.';

export const UPDATE_TEMPLATE_ERROR =
  'Something went wrong saving your template';
export const UPDATE_TEMPLATE_SUCCESS = 'Success! Your template was saved.';

export const PUBLISH_TEMPLATE_SUCCESS =
  'Success! Your template was published to AWS SES.';
export const PUBLISH_TEMPLATE_ERROR =
  'An error occurred when trying to publish your template.';

export const RENEW_API_KEY_ERROR =
  'There was a problem creating a new API key.';
export const RENEW_API_KEY_SUCCESS = 'New API Key successfully created.';

export const CHANGE_AWS_REGION_ERROR =
  'There was a problem changing AWS region.';
export const CHANGE_AWS_REGION_SUCCESS = 'Region was changed.';

export const DELETE_AWS_CREDENTIALS_ERROR =
  'There was a problem deleting your credentials.';
export const DELETE_AWS_CREDENTIALS_SUCCESS =
  'Credentials deleted, AWS Account disconnected.';

export const UPDATE_COMPANY_DETAILS_ERROR =
  'We ran into a problem saving your company details';
export const UPDATE_COMPANY_DETAILS_SUCCESS =
  'Successfully updated company information';

export const INVITE_MEMBER_ERROR =
  'We ran into a problem inviting your collegue!';
export const INVITE_MEMBER_COSTS_ERROR =
  'Please accept additional costs disclaimer';
export const INVITE_MEMBER_SUCCESS = 'Successfully invited new company member';
export const RESEND_MEMBER_INVITATION_SUCCESS =
  'Successfully resent invitation.';
export const RESEND_MEMBER_INVITATION_ERROR =
  'Error while resending invitation. Please try again or contact support.';

export const DELETE_MEMBER_ERROR =
  'We ran into a problem deleting your collegue!';
export const DELETE_MEMBER_SUCCESS = 'Successfully deleted company member';

export const SET_AWS_CREDENTIALS_ERROR =
  'Something went wrong storing your credentials. Please try again!';
export const SET_AWS_CREDENTIALS_COSTS_ERROR =
  'Please accept additional costs disclaimer';
export const SET_AWS_CREDENTIALS_SUCCESS =
  'Credentials valid. Setup successful.';
export const SET_DEFAULT_ACCOUNT_ERROR =
  'An error occurred when switching the account. Please try again or contact our support!';

export const SET_DEFAULT_EMAIL_ERROR =
  'We were not able to set this mail as default. Error message: ';
export const SET_DEFAULT_EMAIL_SUCCESS = 'Success! Set as default.';

export const UPDATE_SENDER_NAME_ERROR =
  'We were not able to update the sender name. Error message: ';
export const UPDATE_SENDER_NAME_SUCCESS = 'Successfully updated sender name.';
export const UPDATE_EMAIL_VISIBILITY_SUCCESS =
  'Successfully changed visibility of email identity.';
export const UPDATE_EMAIL_VISIBILITY_ERROR =
  'Sorry :( Error when updating email visibility.';

export const SEND_MAIL_SUCCESS = 'Success! Your mail was sent.';
export const SEND_MAILS_SUCCESS = 'Success! Your mails were sent.';
export const ERROR_RETRIEVING_AWS_EMAIL_IDENTITIES =
  'An error occurred when fetching your email identities. Please contact the support team';
export const ERROR_RETRIEVING_AWS_DOMAIN_IDENTITIES =
  'An error occurred when fetching your domain identities. Please contact the support team';

export const ERROR_RETRIEVING_ACCOUNT_STATUS =
  'An error occurred when fetching your account status. Please check your permissions or contact the support team';

export const ERROR_RETRIEVING_ACCOUNT_PERMISSIONS =
  'An error occurred when fetching your account permissions. Please check your permissions or contact the support team';

export const INFO_EVALUATING_ACCOUNT_PERMISSIONS =
  'Evaluating your account permissions. This may take a few seconds...';

export const INFO_EVALUATING_ACCOUNT_PERMISSIONS_RESULT =
  'Evaluation finished. Permissions (partially) provided. See details at Settings > AWS Credentials > Semplates Features';

export const SUCCESS_EVALUATING_ACCOUNT_PERMISSIONS =
  'Evaluation finished. All permissions provided. Review at Settings > AWS Credentials > Semplates Features';

export const ERROR_EVALUATING_ACCOUNT_PERMISSIONS =
  'An unknown error occurred when evaluating your account permissions. Please contact the support team';

export const ERROR_EVALUATING_PERMISSIONS_MISSING_REQUIRED_PERMISSIONS =
  'You are missing required permissions. sts:GetCallerIdentity and iam:SimulatePrincipalPolicy are required to evaluate your permissions.';

export const ERROR_RETRIEVING_SENDING_STATISTICS =
  'An error occurred when fetching your sending statistics. Please check your permissions or contact the support team';

export const REMOVE_IDENTITY_ERROR =
  'A problem occured. Make sure you are not deleting the email address which is set as your default. Error message: ';
export const REMOVE_IDENTITY_SUCCESS = 'Identity successfully deleted';

export const ADD_EMAIL_SUCCESS =
  'Email was added. Please click the link in the verification email and reload the identities.';
export const ADD_EMAIL_ERROR = 'Email could not be added. Error message: ';

export const ADD_DOMAIN_SUCCESS =
  'Domain was added. Please add DNS records accordingly.';
export const ADD_DOMAIN_ERROR = 'Domain could not be added. Error message: ';

export const UPGRADE_PLAN_ERROR =
  'Something went wrong while upgrading your plan. Please contact support@semplates.io!';
export const UPGRADE_PLAN_SUCCESS = 'Upgrade successful!.';

export const LOGIN_ERROR = 'Wrong email or password';
export const LOGIN_ERROR_SSO =
  'SSO Login failed. Check if you have popups enabled. Contact our support if issue persists.';

export const TOKEN_LOGIN_ERROR =
  'Something went wrong when trying to log you in.';
export const LOGIN_UNVERIFIED_ERROR =
  'Please verify your email address. We sent you a verification email.';

export const UPDATE_MEMBER_ERROR =
  'An error occurred while updating user groups';
export const UPDATE_MEMBER_SUCCESS = 'Successfully updated user groups.';

export const CREATE_USER_GROUP_SUCCESS =
  'Successfully created a new user group.';
export const CREATE_USER_GROUP_ERROR =
  'An error occurred while creating the user group.';
export const DELETE_USER_GROUP_SUCCESS = 'Successfully deleted the user group.';
export const DELETE_USER_GROUP_ERROR =
  'An error occurred while deleting the user group.';
export const DELETE_USER_GROUP_ERROR_FORBIDDEN =
  'User group contains a user that has no other user group assigned.';
export const DUPLICATE_USER_GROUP_SUCCESS =
  'Successfully duplicated the user group.';
export const DUPLICATE_USER_GROUP_ERROR =
  'An error occurred while duplicating the user group.';
export const UPDATE_USER_GROUP_SUCCESS = 'Successfully updated the user group.';
export const UPDATE_USER_GROUP_ERROR =
  'An error occurred while updating the user group.';

export const CREATE_POLICY_SUCCESS = 'Successfully created a new policy.';
export const CREATE_POLICY_ERROR =
  'An error occurred while creating the policy.';
export const DELETE_POLICY_SUCCESS = 'Successfully deleted the policy.';
export const DELETE_POLICY_ERROR =
  'An error occurred while deleting the policy.';
export const DUPLICATE_POLICY_SUCCESS = 'Successfully duplicated the policy.';
export const DUPLICATE_POLICY_ERROR =
  'An error occurred while duplicating the policy.';
export const UPDATE_POLICY_SUCCESS = 'Successfully updated the policy.';
export const UPDATE_POLICY_ERROR =
  'An error occurred while updating the policy.';
export const CREATE_TAG_ERROR = 'An error occurred while creating a new tag';
export const CREATE_TAG_SUCCESS = 'Successfully created tag.';

export const DELETE_TAG_SUCCESS = 'Successfully deleted tag.';
export const DELETE_TAG_ERROR = 'An error occurred while deleting the tag.';

export const UPDATE_TAG_SUCCESS = 'Successfully updated tag.';
export const UPDATE_TAG_ERROR = 'An error occurred while updating the tag.';

export const UPDATE_TEMPLATE_TAGS_SUCCESS =
  'Successfully updated the template`s tags.';
export const UPDATE_TEMPLATE_TAGS_ERROR =
  'An error occurred while updating the template`s tag.';

export const SEND_TEMPLATE_DATA_ERROR =
  'Error. You tried to send the template with missing or incomplete input data.';

export const IMPORT_TEMPLATES_ERROR =
  'An error occurred while importing your templates from AWS SES.';

export const REACTIVATE_TEMPLATE_VERSION_SUCCESS =
  'Successfully reactivated version.';
export const REACTIVATE_TEMPLATE_VERSION_ERROR =
  'A problem occurred. Please try again or contact support.';
export const SET_PLACEHOLDER_EXTRACTION_ERROR =
  'Extracting placeholders failed. Review your template and try again.';
export const SET_PLACEHOLDER_INTERPOLATION_ERROR =
  'Placeholder interpolation failed. Please review your template syntax, placeholders, handlebars logic and then try again.';

export const CREATE_BLOCK_SUCCESS = 'Block created successfully.';
export const CREATE_BLOCK_ERROR =
  'Failed to create the block. Please try again.';
export const DELETE_BLOCK_SUCCESS = 'Block deleted successfully.';
export const DELETE_BLOCK_ERROR =
  'Failed to delete the block. Please try again.';
export const UPDATE_BLOCK_SUCCESS = 'Block updated successfully.';
export const UPDATE_BLOCK_ERROR =
  'Failed to update the block. Please try again.';
export const UPDATE_BLOCK_TAGS_SUCCESS = 'Block tags updated successfully.';
export const UPDATE_BLOCK_TAGS_ERROR =
  'Failed to update the block tags. Please try again.';
export const UPDATE_BLOCK_ERROR_MULTIPLE_ROWS =
  'Failed to update the block, since it contains multiple rows. Delete row(s) and try again.';
export const SAVE_BLOCK_SUCCESS = 'Templates update succesfully initiated.';
export const SAVE_BLOCK_ERROR =
  'Failed to initiate saving of templates. Please try again.';
export const PUBLISH_BLOCK_SUCCESS =
  'Templates publishing successfully initiated.';
export const PUBLISH_BLOCK_ERROR =
  'Failed to initiate publishing of templates. Please try again.';
export const DUPLICATE_BLOCK_SUCCESS = 'Block duplicated successfully.';
export const DUPLICATE_BLOCK_ERROR =
  'Failed to duplicate block. Please try again.';
export const FETCH_BLOCK_DETAILS_ERROR =
  'Failed to load block details. Please try again.';
export const FETCH_TEMPLATE_BLOCKS_ERROR =
  'Failed to load blocks for template. Please try again.';
export const INFO_SHOW_COPY_CLIPBOARD = 'External ID copied to clipboard';
