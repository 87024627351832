import React from 'react';
import { FastField as Field, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from 'state/actions/layout';
import { TextField } from 'formik-material-ui';
import { Button, Grid, MenuItem, Typography, Tooltip } from '@material-ui/core';
import { FieldWrapper } from 'components/Base';
import { awsRegions } from 'consts';
import { Copy } from 'assets/icons';
import { INFO_SHOW_COPY_CLIPBOARD } from 'state/consts/snackbarMessages';

const AwsCredentialsFields = ({ children, alias, externalId }) => {
  const dispatch = useDispatch();
  const handleCopyExternalId = () => {
    navigator.clipboard.writeText(externalId);
    dispatch(showInfoSnackbar(INFO_SHOW_COPY_CLIPBOARD));
  };

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldWrapper
            label="Account Alias"
            subtitle="Used to distinguish between AWS accounts."
            variant="small"
          >
            <Field
              component={TextField}
              fullWidth
              name="alias"
              variant="outlined"
              size="small"
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={12}>
          <FieldWrapper label="AWS Region" variant="small">
            <Field
              component={TextField}
              disabled={alias ? true : false}
              type="text"
              name="awsRegion"
              select
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            >
              {awsRegions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.region} - {option.value}
                </MenuItem>
              ))}
            </Field>
          </FieldWrapper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom></Typography>
          <FieldWrapper
            label="Role ARN"
            variant="small"
            subtitle="ARN of the role you set up for access."
            link="https://semplates.io/documentation/setup-guide/connect-aws-ses"
            linkText="Learn how to setup your role."
          >
            <Field
              component={TextField}
              fullWidth
              name="roleArn"
              variant="outlined"
              size="small"
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle1" gutterBottom></Typography>
          <FieldWrapper
            label="External ID"
            variant="small"
            subtitle="External ID for the trust policy."
            link="https://semplates.io/documentation/setup-guide/connect-aws-ses"
            linkText="Learn how to use the external ID."
          >
            <Field
              disabled={true}
              component={TextField}
              fullWidth
              name="externalId"
              variant="outlined"
              size="small"
            />
          </FieldWrapper>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Tooltip title="Copy External ID">
            <Button onClick={handleCopyExternalId}>
              <Copy />
            </Button>
          </Tooltip>
        </Grid>
        {children}
      </Grid>
    </Form>
  );
};

export default AwsCredentialsFields;
